import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { GetApiKeyData, addHTTPSToURL, openVncInNewTab, shouldOpenVncInNewTab } from '../utils';
import {
  UserApplicationAccessInfoStatus,
  CreateUserRequestRequestParams,
  UsersService,
  UserRequestInfo,
  UserRequestInfoSpec,
  ApplicationServicesService,
  DesktopClientConfiguration,
  UserDesktopAccessInfoStatus,
  GetChallengeRequestParams,
  Challenge,
  ChallengesService,
  CustomDesktopClientConfig,
  TokensService,
  ListUserResourceAccessInfoRequestParams,
  UserDesktopAccessInfo,
  UserSSHAccessInfo,
} from '@agilicus/angular';
import { NotificationService } from '../notifications/notification.service';
import { takeUntil, filter, concatMap, tap, map, catchError, distinctUntilChanged, switchMap, takeWhile, toArray } from 'rxjs/operators';
import { Subject, Observable, of, interval, from, EMPTY, combineLatest } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppRequestDialogComponent } from '../app-request-dialog/app-request-dialog.component';
import { ResourceAccess } from '../resource-access';
import { ResourceType } from '../resource-type.enum';
import { InternalApplicationDialogComponent } from '../internal-application-dialog/internal-application-dialog.component';
import { ResourceDialogData } from '../resource-dialog-data';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { DesktopConfigDownloadDialogComponent } from '@app/desktop-config-download-dialog/desktop-config-download-dialog.component';
import { VncDesktopDialogComponent } from '@app/vnc-desktop-dialog/vnc-desktop-dialog.component';
import { b64toBlob, downloadBlob } from '@app/file-utils';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Overlay } from '@angular/cdk/overlay';
import { ShareMountInstructionsDialogComponent } from '@app/share-mount-instructions-dialog/share-mount-instructions-dialog.component';
import { MountService } from '@app/services/mount.service';
import { isMobileDevice, isSupportedBrowser } from '@app/browser-utils';
import { ExtensionStateService } from '@app/extension-state/extension-state.service';
import { openWebSsh, updateSshResourceAccess } from '@app/web-ssh-utils';

interface VncDialogState {
  opened: boolean;
  data: ResourceAccess;
}

const initialVncDialogState: VncDialogState = {
  opened: false,
  data: null,
};

const descriptionCharacterLimit = 25;

@Component({
  selector: 'app-app-icon',
  templateUrl: './app-icon.component.html',
  styleUrls: ['./app-icon.component.scss'],
})
export class AppIconComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() public resourceList: Array<ResourceAccess> = [];
  @Input() public isSmallScreen = false;
  @Input() public resourceType = ResourceType.application;
  @Input() public showStatusIcons = true;
  @Input() public searchFilterTextValue = '';
  @Output() public updateRequestIcons = new EventEmitter();
  @ViewChild('contextMenuTrigger') public contextMenuTrigger: MatMenuTrigger;
  @ViewChild('launchMenuTrigger') public launchMenuTrigger: MatMenuTrigger;
  @ViewChild('vncMenuTrigger') public vncMenuTrigger: MatMenuTrigger;
  @ViewChild('sshMenuTrigger') public sshMenuTrigger: MatMenuTrigger;
  public selectedResource: ResourceAccess;
  public launcherVersion: string | undefined | null = null;
  public shareResourceType = ResourceType.share;
  public isExtensionInstalledResult: boolean | undefined;
  public isAlreadyMounted: boolean = false;
  public mountPath: string;

  // This is required in order to reference the enums in the html template.
  public accessLevelEnum = UserApplicationAccessInfoStatus.AccessLevelEnum;

  public addHTTPSToURL = addHTTPSToURL;
  public progressSpinner = false;
  private stopPolling$ = new Subject<void>();
  currentDialog: MatDialogRef<any> | null = null;
  private vncDialogState: VncDialogState;

  constructor(
    private users: UsersService,
    private notificationService: NotificationService,
    private requestDialog: MatDialog,
    private internalDialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private desktopConfigDownloadDialog: MatDialog,
    private vncDesktopDialogComponent: MatDialog,
    private webSshComponent: MatDialog,
    private applicationServicesService: ApplicationServicesService,
    private renderer: Renderer2,
    private router: Router,
    private challengesService: ChallengesService,
    private overlay: Overlay,
    private shareMountDialog: MatDialog,
    private tokensService: TokensService,
    private mountService: MountService,
    private extensionStateService: ExtensionStateService
  ) {
    window.addEventListener('beforeunload', () => {
      this.vncDialogState = this.getVncDialogState();
      this.vncDialogState.opened = false;
      this.saveVncDialogState(this.vncDialogState);
    });
  }

  public isValidResourceType(type: string): boolean {
    const resourceTypeEnumValues = Object.values(ResourceType) as string[];
    return resourceTypeEnumValues.includes(type);
  }

  private isResourceTypeLaunchableWithLauncher(resource: ResourceAccess): boolean {
    return (
      resource.resource_type === ResourceType.desktop ||
      resource.resource_type === ResourceType.ssh ||
      resource.resource_type === ResourceType.launcher
    );
  }

  public preventLeftClick(resource: ResourceAccess): boolean {
    if (!this.isResourceTypeLaunchableWithLauncher(resource)) {
      return false;
    }
    const launcherInfoValue = this.extensionStateService.getCachedLauncherInfo();
    const launcherExtensionInstalledValue = this.extensionStateService.getCachedIsExtensionInstalled();
    const launcherRefreshDataValue = this.mountService.getLauncherRefreshData();
    if (launcherInfoValue === null || launcherInfoValue?.version === null || launcherExtensionInstalledValue === null) {
      return true;
    }
    if (!!launcherExtensionInstalledValue && launcherRefreshDataValue === null) {
      return true;
    }
    return false;
  }

  public ngOnInit(): void {
    const isExtensionInstalled$ = this.extensionStateService.getExtensionInstalledStatus();
    const launcherInfo$ = this.extensionStateService.getLauncherInfo();
    combineLatest([isExtensionInstalled$, launcherInfo$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isExtensionInstalledResult, launcherInfoResult]) => {
        this.isExtensionInstalledResult = isExtensionInstalledResult;
        this.launcherVersion = launcherInfoResult?.version;
      });

    this.vncDialogState = this.getVncDialogState();

    if (!this.vncDialogState.opened) {
      const params = new URLSearchParams(window.location.search);
      const dialogData = this.vncDialogState.data;

      if (params.has('vncdialog') && dialogData) {
        this.openVncDialog(dialogData);
        this.vncDialogState.opened = true;
        this.saveVncDialogState(this.vncDialogState);
      }
    }
    this.changeDetector.detectChanges();
  }

  private getVncDialogState(): VncDialogState {
    const vncDialogStateString = sessionStorage.getItem('vncDialogState');
    return vncDialogStateString ? JSON.parse(vncDialogStateString) : initialVncDialogState;
  }

  private saveVncDialogState(state: VncDialogState): void {
    sessionStorage.setItem('vncDialogState', JSON.stringify(state));
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async getAndSetMountInformation(): Promise<void> {
    const { mountPath, isAlreadyMounted } = await this.mountService.getMountStatus(
      this.getResourceData(this.selectedResource),
      this.selectedResource
    );
    this.mountPath = mountPath;
    this.isAlreadyMounted = isAlreadyMounted;
  }

  public onRequestedResourceClick(resource: ResourceAccess): void {
    this.notificationService.warn('You have already requested access to ' + resource.resource_type + ' "' + resource.name + '"');
  }

  private convertResourceTypeToRequestType(resourceType: ResourceType): UserRequestInfoSpec.RequestedResourceTypeEnum | undefined {
    switch (resourceType) {
      case ResourceType.application:
        return UserRequestInfoSpec.RequestedResourceTypeEnum.application_access;
      case ResourceType.desktop:
        return UserRequestInfoSpec.RequestedResourceTypeEnum.desktop;
      case ResourceType.share:
        return UserRequestInfoSpec.RequestedResourceTypeEnum.file_share_access;
      case ResourceType.ssh:
        return UserRequestInfoSpec.RequestedResourceTypeEnum.ssh;
      default:
        return undefined;
    }
  }

  public onNoAccessResourceClick(requestedResource: ResourceAccess): void {
    const data: ResourceDialogData = {
      resourceInfo: requestedResource,
    };
    const dialogRef = this.requestDialog.open(
      AppRequestDialogComponent,
      getDefaultDialogConfig({
        maxWidth: '400px',
        data,
      })
    );

    // Create an observable that will emit the challenge ID from the createUserRequest
    const challengeId$: Observable<string> = dialogRef.afterClosed().pipe(
      filter((value) => value !== undefined),
      tap(() => (this.progressSpinner = true)),
      takeUntil(this.unsubscribe$),
      concatMap((value) => {
        const convertedResourceRequestType = this.convertResourceTypeToRequestType(requestedResource.resource_type);
        if (!convertedResourceRequestType) {
          return of(undefined);
        }
        return this.requestResourceAccess(requestedResource, convertedResourceRequestType, value).pipe(
          tap((createUserRequestResp) => {
            this.notificationService.success(
              'Request for access to ' + requestedResource.resource_type + ' "' + requestedResource.name + '" was successfully submitted!'
            );
            this.updateRequestIcons.emit();
          }),
          map((createUserRequestResp) => createUserRequestResp.status.challenge_id),
          distinctUntilChanged(),
          catchError((err) => {
            this.progressSpinner = false;
            this.notificationService.error(
              'Error submitting request for access to ' +
                requestedResource.resource_type +
                ' "' +
                requestedResource.name +
                '". ' +
                err.message
            );
            return of(undefined);
          })
        );
      })
    );

    // Subscribe to the observable that will continuously call the getChallenge API until the status is not "issued" or "pending"
    challengeId$
      .pipe(
        filter((challengeId) => challengeId !== undefined),
        switchMap((challengeId) =>
          interval(1000).pipe(
            switchMap(() => from(this.getChallenge(challengeId))),
            takeWhile((response) => response.status.state === 'issued' || response.status.state === 'pending'),
            catchError((error) => {
              if (error.status === 404) {
                this.progressSpinner = false;
                return EMPTY;
              } else {
                console.error('Error fetching challenge response:', error);
                return of(undefined);
              }
            }),
            takeUntil(this.stopPolling$)
          )
        ),
        toArray(), // Collect all emitted values into an array
        takeUntil(this.unsubscribe$)
      )
      // TODO: remove this:
      .pipe(
        map((challengeResp) => {
          // Can get request here and put message in a dialog
          return challengeResp;
        })
      )
      .subscribe((responses) => {
        if (responses.length > 0) {
          this.progressSpinner = false;
          this.updateRequestIcons.emit();
        }
      });
  }

  public requestResourceAccess(
    requestedResource: ResourceAccess,
    requestedResourceType: UserRequestInfoSpec.RequestedResourceTypeEnum,
    requestDetails?: string
  ): Observable<UserRequestInfo> {
    const requestedSubResource = requestedResource.default_role_name ? requestedResource.default_role_name : undefined;
    const params: CreateUserRequestRequestParams = {
      UserRequestInfo: {
        spec: {
          user_id: requestedResource.user_id,
          org_id: requestedResource.org_id,
          requested_resource: requestedResource.id,
          requested_resource_type: requestedResourceType,
          requested_sub_resource: requestedSubResource,
          request_information: requestDetails,
        },
      },
    };
    return this.users.createUserRequest(params);
  }

  public getChallenge(challengeId): Observable<Challenge> {
    const params: GetChallengeRequestParams = {
      challenge_id: challengeId,
    };
    return this.challengesService.getChallenge(params);
  }

  private getDesktop$(resourceAccess: ResourceAccess): Observable<UserDesktopAccessInfo> {
    const requestParameters: ListUserResourceAccessInfoRequestParams = {
      resource_id: resourceAccess.id,
      org_id: resourceAccess.org_id,
      user_id: resourceAccess.user_id,
    };

    return this.users.listDesktopAccessInfo(requestParameters).pipe(
      map((resp) => {
        if (resp.user_desktop_access_info.length < 1) {
          // Handle the error case where no desktop access info is available
          throw new Error('No desktop access info available for the resource.');
        }

        return resp.user_desktop_access_info[0];
      }),
      catchError((error) => {
        this.notificationService.error(`Error fetching desktop access info: ${error}`);
        throw error;
      })
    );
  }

  private getSsh$(resourceAccess: ResourceAccess): Observable<UserSSHAccessInfo> {
    const requestParameters: ListUserResourceAccessInfoRequestParams = {
      resource_id: resourceAccess.id,
      org_id: resourceAccess.org_id,
      user_id: resourceAccess.user_id,
    };

    return this.users.listSshAccessInfo(requestParameters).pipe(
      map((resp) => {
        if (resp.user_ssh_access_info.length < 1) {
          // Handle the error case where no desktop access info is available
          throw new Error('No ssh access info available for the resource.');
        }

        return resp.user_ssh_access_info[0];
      }),
      catchError((error) => {
        this.notificationService.error(`Error fetching ssh access info: ${error}`);
        throw error;
      })
    );
  }

  private handleApplicationLeftClick(resourceAccess: ResourceAccess): void {
    window.open(`${addHTTPSToURL(resourceAccess.resource_url)}`, '_blank');
  }

  private handleShareLeftClick(resourceAccess: ResourceAccess): void {
    this.router.navigate([`/shares/${resourceAccess.id}/file-manager`], {
      queryParams: { org_id: resourceAccess.org_id },
    });
  }

  private translateDesktopResponse(resp: UserDesktopAccessInfo, resourceAccess: ResourceAccess): ResourceAccess {
    const updatedResourceAccess: ResourceAccess = { ...resourceAccess };

    if (!!resp.status.desktop_type) {
      updatedResourceAccess.desktop_type = resp.status.desktop_type;
    }
    if (!!resp.status.config_overrides) {
      updatedResourceAccess.config_overrides = resp.status.config_overrides;
    }
    if (!!resp.status.config_overrides_error) {
      updatedResourceAccess.config_overrides_error = resp.status.config_overrides_error;
    }
    if (!!resp.status.resource_uri) {
      updatedResourceAccess.resource_url = resp.status.resource_uri;
    }

    return updatedResourceAccess;
  }

  private isOperationInProgress = false;

  private handleDesktopLeftClick(resourceAccess: ResourceAccess): void {
    if (this.isOperationInProgress) {
      return;
    }

    this.isOperationInProgress = true;
    this.getDesktop$(resourceAccess).subscribe(
      (resp) => {
        const updatedResourceAccess = this.translateDesktopResponse(resp, resourceAccess);

        if (updatedResourceAccess.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.vnc) {
          this.handleVncDesktopLeftClick(updatedResourceAccess);
        } else {
          this.handleRdpDesktopLeftClick(updatedResourceAccess);
        }
      },
      (error) => {},
      () => {
        this.isOperationInProgress = false;
      }
    );
  }

  private handleVncDesktopLeftClick(resourceAccess: ResourceAccess): void {
    if (this.canLaunchWithLauncher() && this.canResourceLaunchWithLauncher(resourceAccess)) {
      this.launchResourceFunc(resourceAccess);
      return;
    }
    this.openVncDesktop(resourceAccess);
    this.storeResourceData(resourceAccess);
  }

  private handleRdpDesktopLeftClick(resourceAccess: ResourceAccess): void {
    if (this.canLaunchWithLauncher() && this.canResourceLaunchWithLauncher(resourceAccess)) {
      this.launchResourceFunc(resourceAccess);
      return;
    }
    this.openDesktopConfigDownloadDialog(resourceAccess);
  }

  private handleSshLeftClick(resourceAccess: ResourceAccess): void {
    if (this.canLaunchWithLauncher() && this.canResourceLaunchWithLauncher(resourceAccess)) {
      this.launchResourceFunc(resourceAccess);
    } else {
      if (isMobileDevice()) {
        this.openSshInNewTab(resourceAccess);
      } else {
        this.getSsh$(resourceAccess).subscribe((resp) => {
          const updatedResourceAccess = updateSshResourceAccess(resourceAccess, resp);
          this.openSshManually(updatedResourceAccess);
        });
      }
    }
  }

  private handleLauncherLeftClick(resourceAccess: ResourceAccess): void {
    if (this.canLaunchWithLauncher() && this.canResourceLaunchWithLauncher(resourceAccess)) {
      this.launchResourceFunc(resourceAccess);
    } else {
      this.notificationService.error(this.getResourceLauncherTooltip(resourceAccess.resource_type) + '. Please run from your start menu');
    }
  }

  private openSshManually(resourceAccess: ResourceAccess): void {
    openWebSsh(resourceAccess, this.webSshComponent);
  }

  public openSshManuallyFromRightClick(): void {
    this.openSshManually(this.selectedResource);
  }

  public onGrantedAccessResourceLeftClick(event: MouseEvent, resourceAccess: ResourceAccess): void {
    if (event.button !== 0) {
      return;
    }
    // Left click
    if (this.preventLeftClick(resourceAccess)) {
      return;
    }
    switch (resourceAccess.resource_type) {
      case ResourceType.application:
        this.handleApplicationLeftClick(resourceAccess);
        break;
      case ResourceType.share:
        this.handleShareLeftClick(resourceAccess);
        break;
      case ResourceType.internal:
        this.openInternalDialog(resourceAccess);
        break;
      case ResourceType.desktop:
        this.handleDesktopLeftClick(resourceAccess);
        break;
      case ResourceType.ssh:
        this.handleSshLeftClick(resourceAccess);
        break;
      case ResourceType.launcher:
        this.handleLauncherLeftClick(resourceAccess);
        break;
      default:
      // This will just open the normal default left click menu
    }
  }

  public onGrantedAccessResourceRightClick(event: MouseEvent, resourceAccess: ResourceAccess): void {
    // This will stop the regular right click menu from opening
    event.preventDefault();
    const buttonRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const x = buttonRect.left + window.pageXOffset;
    const y = buttonRect.top + window.pageYOffset;
    if (resourceAccess.resource_type === ResourceType.desktop) {
      this.getDesktop$(resourceAccess).subscribe((resp) => {
        const updatedResourceAccess = { ...resourceAccess };
        if (!!resp.status.desktop_type) {
          updatedResourceAccess.desktop_type = resp.status.desktop_type;
        }
        if (!!resp.status.config_overrides) {
          updatedResourceAccess.config_overrides = resp.status.config_overrides;
        }
        if (!!resp.status.config_overrides_error) {
          updatedResourceAccess.config_overrides_error = resp.status.config_overrides_error;
        }
        if (!!resp.status.resource_uri) {
          updatedResourceAccess.resource_url = resp.status.resource_uri;
        }
        if (this.shouldOpenRdpDesktopRightClickMenu(updatedResourceAccess)) {
          this.openRightClickMenu(x, y, 'launcher-menu-button', this.launchMenuTrigger);
        }
        if (this.shouldOpenVncDesktopRightClickMenu(updatedResourceAccess)) {
          this.openRightClickMenu(x, y, 'vnc-menu-button', this.vncMenuTrigger);
        }
        this.selectedResource = updatedResourceAccess;
      });
    }
    if (resourceAccess.resource_type === ResourceType.ssh) {
      this.getSsh$(resourceAccess).subscribe((resp) => {
        const updatedResourceAccess = updateSshResourceAccess(resourceAccess, resp);

        if (this.shouldOpenSshRightClickMenu(updatedResourceAccess)) {
          this.openRightClickMenu(x, y, 'ssh-menu-button', this.sshMenuTrigger);
        }
        this.selectedResource = updatedResourceAccess;
      });
    }
    this.selectedResource = resourceAccess;
    if (this.shouldSetMountInformation(resourceAccess)) {
      this.getAndSetMountInformation();
    }
    if (this.shouldOpenShareRightClickMenu(resourceAccess)) {
      this.openRightClickMenu(x, y, 'menu-button', this.contextMenuTrigger);
    }
  }

  private shouldOpenSshRightClickMenu(resourceAccess: ResourceAccess): boolean {
    return resourceAccess.resource_type === ResourceType.ssh && !!this.sshMenuTrigger?.menu;
  }

  private shouldOpenRdpDesktopRightClickMenu(resourceAccess: ResourceAccess): boolean {
    return (
      resourceAccess.resource_type === ResourceType.desktop &&
      resourceAccess.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.rdp &&
      !!this.launchMenuTrigger?.menu
    );
  }

  private shouldOpenVncDesktopRightClickMenu(resourceAccess: ResourceAccess): boolean {
    return (
      resourceAccess.resource_type === ResourceType.desktop &&
      resourceAccess.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.vnc &&
      !!this.vncMenuTrigger?.menu
    );
  }

  private shouldOpenShareRightClickMenu(resourceAccess: ResourceAccess): boolean {
    return resourceAccess.resource_type === ResourceType.share && !!this.contextMenuTrigger?.menu;
  }

  private shouldSetMountInformation(resourceAccess: ResourceAccess): boolean {
    return (
      this.canLaunchWithLauncher() &&
      this.canResourceLaunchWithLauncher(resourceAccess) &&
      resourceAccess.resource_type === ResourceType.share
    );
  }

  private openRightClickMenu(x: number, y: number, buttonId: string, menuTrigger: MatMenuTrigger): void {
    // This will stop the regular right click menu from opening
    event.preventDefault();

    const button = document.getElementById(buttonId);
    button.style.position = 'fixed';
    button.style.left = `${x + 80}px`;
    button.style.top = `${y + 120}px`;

    menuTrigger.openMenu();
  }

  public launchResourceFunc(resourceAccess: ResourceAccess) {
    this.mountService.launchResource(this.getResourceData(resourceAccess), resourceAccess);
  }

  public launchSelectedResource() {
    this.mountService.launchResource(this.getResourceData(this.selectedResource), this.selectedResource);
  }

  private getResourceData(resource: ResourceAccess): GetApiKeyData {
    const resourceData: GetApiKeyData = {
      userId: resource.user_id,
      orgId: resource.org_id,
      resourceId: resource.id,
      resourceName: resource.name,
      tokensService: this.tokensService,
      resourceType: resource.resource_type,
    };
    return resourceData;
  }

  public openManualDesktopDownload(): void {
    this.openDesktopConfigDownloadDialog(this.selectedResource);
  }

  public openManualVncDesktop(): void {
    this.openVncDesktop(this.selectedResource);
    this.storeResourceData(this.selectedResource);
  }

  public openShareMountDialog(): void {
    const data: ResourceDialogData = { resourceInfo: this.selectedResource };
    const dialogRef = this.shareMountDialog.open(ShareMountInstructionsDialogComponent, getDefaultDialogConfig({ data }));
  }

  public async mountDrive(): Promise<void> {
    await this.mountService.mountDrive(this.getResourceData(this.selectedResource), this.selectedResource);
    this.getAndSetMountInformation();
  }

  public async unMountDrive(): Promise<void> {
    await this.mountService.unMountDrive(this.getResourceData(this.selectedResource), this.selectedResource);
    this.getAndSetMountInformation();
  }

  public isMountDisabled(): boolean {
    return this.mountService.getLauncherRefreshData()
      ? !this.canLaunchWithLauncher() || !this.mountService.doesSupportMount()
      : !this.canLaunchWithLauncher();
  }

  public isResourceDisabled(resource: ResourceAccess): boolean {
    if (resource.resource_type !== ResourceType.launcher) {
      return false;
    }
    /* launcher type resources are always diabled by default, unless there is launch Refresh data that says
    it can be launched.
    */
    return !!this.mountService.getLauncherRefreshData() ? !this.canResourceLaunchWithLauncher(resource) : true;
  }

  public disableLaunchWithLauncher(): boolean {
    return !this.canLaunchWithLauncher() || (this.selectedResource ? !this.canResourceLaunchWithLauncher(this.selectedResource) : false);
  }

  private canLaunchWithLauncher(): boolean {
    return !!this.mountService.canUseLauncherWithExtension(this.isExtensionInstalledResult, this.launcherVersion);
  }

  private canResourceLaunchWithLauncher(resourceAccess: ResourceAccess): boolean {
    return !!this.mountService.doesResourceExistInLauncherRefreshData(resourceAccess);
  }

  public isRightClickEnabledResource(resource: ResourceAccess): boolean {
    return (
      resource.resource_type === ResourceType.share ||
      resource.resource_type === ResourceType.ssh ||
      resource.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.rdp
    );
  }

  public getMountButtonTooltip(): string {
    return this.mountService.getMountTooltipMessage(this.isExtensionInstalledResult, this.launcherVersion, isSupportedBrowser());
  }

  public getUnmountButtonTooltip(): string {
    return 'This share is currently mounted on ' + this.mountPath + ' drive. Click to unmount this share';
  }

  public openInternalDialog(resource: ResourceAccess): void {
    const data: ResourceDialogData = {
      resourceInfo: resource,
    };
    const dialogRef = this.internalDialog.open(
      InternalApplicationDialogComponent,
      getDefaultDialogConfig({
        data,
      })
    );
  }

  public openSshInNewTab(resource: ResourceAccess) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/web-ssh/' + resource.id]));
    const newWindow = window.open(url, '_blank');

    if (!newWindow) {
      alert('Pop-ups are blocked on your browser. Please enable pop-ups to continue.');
    }
  }

  private addVncToUrl(): void {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('vncdialog', 'true');
    url.search = params.toString();
    const updatedURL = url.toString();
    history.replaceState(null, '', updatedURL);
  }

  private storeResourceData(resource: ResourceAccess): void {
    this.vncDialogState.data = resource;
    this.saveVncDialogState(this.vncDialogState);
    if (shouldOpenVncInNewTab()) {
      return;
    }
    this.addVncToUrl();
  }

  private closeExistingDialog(): void {
    if (this.currentDialog) {
      this.currentDialog.close();
      this.currentDialog = undefined;
    }
  }

  private openVncDialog(resource: ResourceAccess): void {
    this.closeExistingDialog();
    const data: ResourceDialogData = {
      resourceInfo: resource,
    };
    const dialogRef = this.vncDesktopDialogComponent.open(
      VncDesktopDialogComponent,
      getDefaultDialogConfig({
        maxWidth: 'unset',
        height: '98%',
        width: '100vw',
        data,
        panelClass: 'vnc-dialog',
      })
    );
    this.currentDialog = dialogRef;
  }

  public openVncDesktop(resource: ResourceAccess): void {
    if (shouldOpenVncInNewTab()) {
      this.closeExistingDialog();
      openVncInNewTab(this.router, resource.id);
    } else {
      this.openVncDialog(resource);
    }
  }

  public openDesktopConfigDownloadDialog(resource: ResourceAccess): void {
    const data: ResourceDialogData = {
      resourceInfo: resource,
    };
    const dialogRef = this.desktopConfigDownloadDialog.open(
      DesktopConfigDownloadDialogComponent,
      getDefaultDialogConfig({
        data,
      })
    );

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((value) => !!value),
        filter((_) => {
          if (resource.config_overrides_error && resource.config_overrides_error.trim() !== '') {
            this.notificationService.error(`Your desktop config is invalid: ${resource.config_overrides_error}`);
            return false;
          }
          return true;
        }),
        concatMap((_) => {
          return this.downloadDesktopConfig(resource, resource.config_overrides);
        }),
        catchError((error) => {
          if (error.status === 400) {
            if (error.error.detail && error.error.detail.includes('custom_config')) {
              const errorMessage = error.error.detail;
              this.notificationService.error(`Error: ${errorMessage}`);
            } else if (
              error.error.error_message &&
              (error.error.error_message.includes('cannot override') || error.error.error_message.includes('cannot remove'))
            ) {
              const errorMessage = error.error.error_message;
              this.notificationService.error(`Error: ${errorMessage}`);
            }
          } else {
            this.notificationService.error('There was a problem downloading the desktop configuration file. Please try again.');
          }
          // Return an empty observable to stop the chain
          return of(null);
        })
      )
      .subscribe((resp) => {
        const blob = b64toBlob(resp.generated_config.configuration_file, resp.generated_config.configuration_file_media_type);
        downloadBlob(blob, this.renderer, 'rdp', `${data.resourceInfo.name}-config`);
      });
  }

  private downloadDesktopConfig(
    resource: ResourceAccess,
    metadataArray: CustomDesktopClientConfig[]
  ): Observable<DesktopClientConfiguration> {
    return this.applicationServicesService.createClientConfiguration({
      resource_id: resource.id,
      DesktopClientConfiguration: {
        user_id: resource.user_id,
        org_id: resource.org_id,
        custom_config: metadataArray,
      },
    });
  }

  public getNoAccessToolTip(resource: ResourceAccess): string {
    return `Click to request access to this ${resource.resource_type}`;
  }

  public getDefaultResourceIcon(resource: ResourceAccess): string {
    switch (resource.resource_type) {
      case ResourceType.application:
        return 'web_asset';
      case ResourceType.share:
        return 'cloud_circle';
      case ResourceType.desktop:
        if (resource.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.vnc) {
          return 'screen_share';
        }
        if (!!resource.is_remote_app) {
          return 'laptop';
        }
        return 'desktop_windows';
      case ResourceType.ssh:
        return 'https';
      case ResourceType.launcher:
        return 'launch';
      default:
        return '';
    }
  }

  public onCloseSpinner(): void {
    this.progressSpinner = false;
    this.stopPolling$.next();
  }

  public getResourceLauncherTooltip(resourceType?: ResourceType): string {
    if (!this.isExtensionInstalledResult) {
      return 'Extension not installed';
    } else if (!this.launcherVersion) {
      return 'desktop integration not installed';
    } else if (!isSupportedBrowser) {
      return 'Not supported by this browser';
    }
    return 'Click to launch this ' + resourceType ? resourceType : this.resourceType;
  }

  public getResourceDescriptionDisplayValue(resource: ResourceAccess): string {
    if (!resource.description) {
      return '';
    }
    if (resource.description.length > descriptionCharacterLimit) {
      return `${resource.description.substring(0, descriptionCharacterLimit)}...`;
    }
    return resource.description;
  }

  public getDescriptionTooltipText(resource: ResourceAccess): string {
    const fullDescription = !!resource.description ? resource.description : '';
    // Display a tooltip only if description is trimmed:
    return descriptionCharacterLimit < fullDescription.length ? fullDescription : '';
  }
}
