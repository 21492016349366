import { HttpHeaders } from '@angular/common/http';

export function getIgnoreErrorsKey(): string {
  return 'ag_internal_ignore_errors';
}

export function getIgnoreErrorsValue(): string {
  return 'true';
}

export function getIgnoreErrorsHeader(): HttpHeaders {
  const newHeaders = new HttpHeaders();
  return newHeaders.append(getIgnoreErrorsKey(), getIgnoreErrorsValue());
}
