import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { User, UserFileShareAccessInfo, UserFileShareAccessInfoStatus, UsersService } from '@agilicus/angular';
import { environment } from './../environments/environment';
import { SwPush } from '@angular/service-worker';
import { combineLatest, Observable, Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SubMenuItem } from './submenu/submenu/submenu.component';
import { getBrandLogoImagePath, getShareAccessInfo, getUserNameFromUser, isLinkActive } from './utils';

import { EmergencyContactInfoComponent } from '@app/emergency-contact-info/emergency-contact-info.component';
import { UserInfoComponent } from '@app/user-info/user-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export function constructScopes(scopeList: string[]): string {
  return scopeList.join(' ');
}

import { AuthService } from './services/auth-service/auth-service.service';
import { RdpOptionsDialogComponent } from './rdp-options-dialog/rdp-options-dialog.component';
import { getDefaultDialogConfig } from './dialog-utils';
import { ExtensionStateService } from './extension-state/extension-state.service';
import { MountService } from './services/mount.service';
import { dateIsWithinDurationAgo } from './date-utils';
import { isMobileDevice } from './browser-utils';
import { MfaEnrollComponent } from './mfa-enroll/mfa-enroll.component';
import { getProfileAppId, getUserMetadata$, setUserPreferenceState } from './utils/user-metadata.utils';
import { UserPreferencesDialogComponent, UserPreferencesDialogData } from './user-preferences-dialog/user-preferences-dialog.component';
import { AppInitService } from './app.init';

export enum ProfileVersion {
  STABLE = 'STABLE',
  BETA = 'BETA',
  ALPHA = 'ALPHA',
}

const autoLoginHoldoffSeconds: number = 120;
const mfaPaths: Map<string, boolean> = new Map();
mfaPaths.set('/handle_launcher_mfa', true);
mfaPaths.set('/handle_browser_mfa', true);
mfaPaths.set('/handle_mfa_webpush', true);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public title = 'profile';
  public user$: Observable<User> | undefined = undefined;
  public newWindow: Window | null;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));
  private routerParams$: Observable<Params>;
  public forceEnroll = false;
  public userId: string | null = null;
  public orgId: string | null = null;
  public shareAccessInfo: Array<UserFileShareAccessInfo> = [];
  public imagePath = getBrandLogoImagePath(this.appInitService);

  public launcherVersion: string | undefined | null = null;
  private refreshButtonState: string = 'default';
  public lastRefresh: Date | undefined;

  public profileVersion = ProfileVersion;
  public profileVersionString = 'stable';
  public currentProfileVersion: ProfileVersion = ProfileVersion.STABLE;
  public isSmallScreen = false;
  private smallScreenSizeBreakpoint = 900;
  private isLauncherInfoLoaded = false;

  private showHomeButton = true;

  public shareSubMenuItems: Array<SubMenuItem> = [
    {
      name: 'Share Access',
      routerLink: '/shares/share-access',
    },
  ];

  public currentRouterLink = '';

  public isLinkActive = isLinkActive;
  public isMobileDevice = isMobileDevice;

  constructor(
    private swPush: SwPush,
    private emergencyContactDialog: MatDialog,
    private userInfoDialog: MatDialog,
    private mfaInfoDialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private users: UsersService,
    public router: Router,
    private authService: AuthService,
    private rdpOptionsDialog: MatDialog,
    private userPreferencesDialog: MatDialog,
    private extensionStateService: ExtensionStateService,
    private mountService: MountService,
    private appInitService: AppInitService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.showHomeButton = !event.url.includes('applications');
    });

    this.newWindow = window;
    this.routerParams$ = this.route.queryParams;
    this.user$ = this.authService.getAuth().user$();

    this.swPush.messages.subscribe((event) => {
      console.log('swPush.messages => ', event);
    });
    this.swPush.notificationClicks.subscribe((event) => {
      // This only works while the angular app is running
      console.log('swPush.notificationClicks => ', event);
    });
    this.routerParams$.pipe(takeUntil(this.unsubscribe$)).subscribe((routerParamsResp) => {
      if (routerParamsResp.logout) {
        localStorage.clear();
        this.router.navigate(['/']);
      }

      this.forceEnroll = routerParamsResp.force_enroll || false;
      // Allow launching to a specific version, set the cookie for refresh.
      // This is intended for debug, launch.json url= ...?verCookie=dev
      if (routerParamsResp.verCookie) {
        this.cookieService.set('ver', routerParamsResp.verCookie, 1, '/', undefined, true, 'Lax');
      }
    });
  }

  public getShowHomeButton(): boolean {
    return this.showHomeButton;
  }

  public goBackToHome(): void {
    this.router.navigate(['/applications']);
  }

  private doResize(): void {
    if (window.innerWidth < this.smallScreenSizeBreakpoint) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.doResize();
  }

  private initLauncherInfo(force: boolean): void {
    const launcherInfo$ = this.extensionStateService.getLauncherInfo(force);
    launcherInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((launcherInfoResult) => {
      if (!launcherInfoResult) {
        return;
      }
      const currentLauncherVersion = launcherInfoResult?.version;
      if (!!currentLauncherVersion && currentLauncherVersion !== this.launcherVersion) {
        // If there is a new launcher version, do the refresh and update local vars:
        this.refreshLauncher();
        this.launcherVersion = currentLauncherVersion;
        this.lastRefresh = launcherInfoResult?.last_refresh;
      }
    });
  }

  public ngOnInit(): void {
    const verc = this.cookieService.get('ver');
    if (verc === 'beta') {
      this.profileVersionString = 'beta';
      this.currentProfileVersion = ProfileVersion.BETA;
    } else if (verc === 'alpha') {
      this.profileVersionString = 'alpha';
      this.currentProfileVersion = ProfileVersion.ALPHA;
    } else {
      this.profileVersionString = 'stable';
      this.currentProfileVersion = ProfileVersion.STABLE;
    }
    this.isSmallScreen = window.innerWidth < this.smallScreenSizeBreakpoint;
    this.doResize();

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (!user && this.doAutomaticSignIn()) {
        this.onLoginClick();
      }
      const at = this.authService.getAuth().access_token();
      if (user && user.id && user.org_id && at) {
        this.userId = user.id;
        this.orgId = user.org_id;

        this.initLauncherInfo(true);
        this.startPeriodicRefresh();

        localStorage.setItem('user_id', user.id);
        localStorage.setItem('user_name', getUserNameFromUser(user));

        EmergencyContactInfoComponent.init(this.users, user.id, user.org_id);

        localStorage.setItem('org_id', user.org_id);
        localStorage.setItem('access_token', at);
        const shareAccessInfo$ = getShareAccessInfo(this.userId, this.orgId, this.users);
        const userProfileMetaData$ = getUserMetadata$(this.users, this.userId, this.orgId, { app_id: getProfileAppId() });
        combineLatest([shareAccessInfo$, userProfileMetaData$])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(([shareAccessResp, userProfileMetaDataResp]) => {
            if (!!userProfileMetaDataResp && userProfileMetaDataResp.length !== 0) {
              setUserPreferenceState(userProfileMetaDataResp);
            }
            this.shareAccessInfo = shareAccessResp.filter(
              (share) => share.status.access_level === UserFileShareAccessInfoStatus.AccessLevelEnum.granted
            );
            this.setShareSubMenuItems();
          });
      }
    });
  }

  public ngAfterViewInit(): void {
    const targetElement = document.getElementById('agilicus-anyx-clickonce');
    const observer = new MutationObserver(this.onProfileIndexHtmlContentChange.bind(this));

    observer.observe(targetElement, {
      attributes: true,
    });
  }

  public refreshLauncher(): void {
    this.mountService.refreshLauncher('launcherRefreshButton', this.refreshButtonState);
  }

  private startPeriodicRefresh(): void {
    setInterval(() => {
      this.checkAndRefreshAtInterval();
    }, 3600000);
  }

  private checkAndRefreshAtInterval(): void {
    if (!!this.lastRefresh) {
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - this.lastRefresh.getTime();
      const hoursDifference = timeDifference / (1000 * 3600);
      if (hoursDifference <= 4) {
        // Do not need to refresh yet.
        return;
      }
    }
    this.refreshLauncher();
  }

  private onProfileIndexHtmlContentChange(_: MutationRecord[]): void {
    this.initLauncherInfo(true);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get showLoginHint(): boolean {
    const slh: boolean = this.forceEnroll || this.userId !== null || this.isMfaEnroll() || this.isMfaChallenge();
    return slh === false;
  }

  get version(): string {
    // Purposely not using the override, use the compiled in version
    return environment.versions.app;
  }
  get name(): string | null {
    return this.authService.getAuth().name();
  }
  public onEmergencyContactClick(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId: this.userId,
      orgId: this.orgId,
    };
    this.emergencyContactDialog.open(EmergencyContactInfoComponent, dialogConfig);
  }
  public onUserInfoClick(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId: this.userId,
      orgId: this.orgId,
      idToken: this.authService.auth.id_token(),
    };
    this.userInfoDialog.open(UserInfoComponent, dialogConfig);
  }

  public onMultifactorScreenClick(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      showDialog: true,
    };
    dialogConfig.panelClass = 'mfa-enroll-dialog-container';
    this.mfaInfoDialog.open(MfaEnrollComponent, dialogConfig);
  }

  public onRdpOptionsClick(): void {
    const data = {
      userId: this.userId,
      orgId: this.orgId,
    };
    const dialogRef = this.rdpOptionsDialog.open(
      RdpOptionsDialogComponent,
      getDefaultDialogConfig({
        maxWidth: 'unset',
        width: '70vw',
        data,
      })
    );

    dialogRef.afterClosed().subscribe((status: string) => {
      if (status == 'refreshDesktops') {
        const currentUrl = this.router.routerState.snapshot.url;
        if (currentUrl.includes('/desktops')) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/desktops'], {
              queryParams: { org_id: this.orgId },
            })
          );
        }
      }
    });
  }

  public onSettingsOptionsClick(): void {
    const data: UserPreferencesDialogData = {
      userId: this.userId,
      orgId: this.orgId,
    };
    const dialogRef = this.userPreferencesDialog.open(
      UserPreferencesDialogComponent,
      getDefaultDialogConfig({
        maxWidth: '585px',
        width: '90%',
        data,
      })
    );
  }

  public async onLoginClick(): Promise<void> {
    return this.authService.login();
  }

  private async logout(localStateOnly: boolean): Promise<void> {
    await this.mountService.logout();
    const url = new URL(window.location.href);
    url.pathname = 'logout-bounce';
    return this.authService.getAuth().logout(url.toString(), localStateOnly);
  }

  public async onLogoutClick(): Promise<void> {
    this.user$ = undefined;
    await this.logout(false);
  }

  public async setVerCookieAndLogout(ver: string): Promise<void> {
    this.setVerCookie(ver);
    await this.logout(true);
    this.router.navigate(['/logout-bounce']);
    location.reload();
  }
  public onVerClickAlpha(): Promise<void> {
    this.currentProfileVersion = ProfileVersion.ALPHA;
    return this.setVerCookieAndLogout('alpha');
  }
  public onVerClickBeta(): Promise<void> {
    this.currentProfileVersion = ProfileVersion.BETA;
    return this.setVerCookieAndLogout('beta');
  }

  public onVerClickStable(): Promise<void> {
    this.currentProfileVersion = ProfileVersion.STABLE;
    return this.setVerCookieAndLogout('stable');
  }

  private setVerCookie(ver: string): void {
    // Expire in 1day
    this.cookieService.set('ver', ver, 1, '/', undefined, true, 'Lax');
  }

  private isMfaEnroll(): boolean {
    return window.location.pathname === '/mfa-enroll';
  }

  private isMfaChallenge(): boolean {
    const resp = mfaPaths.get(window.location.pathname);
    return resp === true;
  }

  private setShareSubMenuItems(): void {
    for (const share of this.shareAccessInfo) {
      const shareMenuItem: SubMenuItem = {
        name: share.status.share_name,
        routerLink: `/shares/${share.status.share_id}/file-manager`,
      };
      this.shareSubMenuItems.push(shareMenuItem);
    }
  }

  /**
   * Receives the currentRouterLink from the active submenu component
   * so that other submenu components can be updated with this value.
   * Ensures only the current link is highlighted, or its parent if
   * the menu is collapsed.
   */
  public updateEvent(currentRouterLink: string): void {
    this.currentRouterLink = currentRouterLink.slice(currentRouterLink.indexOf('/') + 1);
  }

  public getGravatarSize(): number {
    return 100;
  }

  private doAutomaticSignIn(): boolean {
    if (this.isMfaEnroll()) {
      return false;
    }

    if (this.isMfaChallenge()) {
      return false;
    }

    if (!this.authService.isRememberMeCookieSet()) {
      return false;
    }

    const lastLogin = this.authService.lastLoginAttempt();
    if (!lastLogin) {
      return true;
    }

    return !dateIsWithinDurationAgo(lastLogin, autoLoginHoldoffSeconds);
  }

  /*
   * This is a fairly simple 'hack' to prevent users from unwittingly
   * coming to our endpoint rather than their own.
   */
  get showUnwelcomeMessage(): boolean {
    const slh: boolean = this.forceEnroll || this.userId !== null || window.location.pathname === '/mfa-enroll';
    const valid = document.location.hostname === 'profile.agilicus.com' || document.location.hostname === 'profile.agilicus.cloud';
    console.log('Welcome = ', valid);
    return slh || valid;
  }
}
