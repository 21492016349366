import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = Math.random() * 1000,
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || error.status < 500) {
          return throwError(error);
        }
        return timer(retryAttempt * scalingDuration);
      })
    );
  };
